import { useNuxtApp, type AsyncDataOptions } from '#app'
import type { LoginFormType } from '~/types'
import { token } from '~/data/local'
import { useMyAuthStore } from '~/stores/auth.store'

const asyncDataOptions: AsyncDataOptions<unknown> = {
  server: false
} as const

export const useLoginContext = () => {
  const { $api } = useNuxtApp()
  const authStore = useMyAuthStore()
  const loginHandler = (params: LoginFormType) =>
    $api.auth.login(params, asyncDataOptions)
  const getProfileHandler = () => $api.auth.getProfile()

  const setToken = async (tokenData: {
    accessToken: string
    refreshToken: string
  }) => {
    const tokenPref = token()
    await tokenPref.set(JSON.stringify(tokenData))
  }

  const setProfileToStore = (profile: any) => {
    authStore.setProfile(profile)
  }

  return {
    loginHandler,
    getProfileHandler,
    setToken,
    setProfileToStore
  }
}
