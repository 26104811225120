export enum Gender {
  Male = 1,
  Female = 2,
  Other = 3
}

export enum UserRole {
  Admin = 1,
  Employee = 2,
  Student = 3
}

export enum UserStatus {
  Pending = 1,
  Rejected = 2,
  Approved = 3,
  Reserved = 4
}
