import type { FetchOptions } from 'ofetch';
import { type AsyncDataOptions, useAsyncData } from '#app';
import FetchFactory from '../factory';

class ClassModule extends FetchFactory<unknown> {
    private RESOURCE = '/classes'

    private fetchOptions: FetchOptions<'json'> = {
        headers: {
            'Accept-Language': 'en-US',
            'Content-Type': 'application/json'
        }
    }

    async getClasses(params: { pageNumber: number; pageSize: number; search: string, filters: { key: string; value: string }[] }, asyncDataOptions?: AsyncDataOptions<unknown>) {
        const queryParams = new URLSearchParams({
            pageNumber: params.pageNumber.toString(),
            pageSize: params.pageSize.toString(),
            searchText: params.search
        });

        if (params.filters) {
            params.filters.forEach(filter => {
                queryParams.append(`filters[${filter.key}]`, filter.value);
            });
        }
        queryParams.append('SortOption.ColumnName', 'id');
        queryParams.append('SortOption.IsDescending', 'true');

        return useAsyncData(
            'getClasses',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}?${queryParams.toString()}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }
}

export default ClassModule