import type { FetchOptions } from 'ofetch';
import { type AsyncDataOptions, useAsyncData } from '#app';
import FetchFactory from '../factory';

class StudentModule extends FetchFactory<unknown> {
    private RESOURCE = '/users'

    private fetchOptions: FetchOptions<'json'> = {
        headers: {
            'Accept-Language': 'en-US',
            'Content-Type': 'application/json'
        }
    }


    async getStudents(params: { pageNumber: number; pageSize: number; search: string, filters: { key: string; value: string }[] }, asyncDataOptions?: AsyncDataOptions<unknown>) {
        const queryParams = new URLSearchParams({
            pageNumber: params.pageNumber.toString(),
            pageSize: params.pageSize.toString(),
            searchText: params.search
        });

        if (params.filters) {
            params.filters.forEach(filter => {
                queryParams.append(`filters[${filter.key}]`, filter.value);
            });
        }
        queryParams.append('SortOption.ColumnName', 'id');
        queryParams.append('SortOption.IsDescending', 'true');

        return useAsyncData(
            'getStudents',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}?${queryParams.toString()}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async getStudentById(id: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getStudentById',
            () => this.call('GET', `${this.RESOURCE}/${id}`, undefined, this.fetchOptions),
            asyncDataOptions
        )
    }

    async getStudentVideoUrl(id: string, videoName: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getStudentVideoUrl',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}/${id}/videos/${videoName}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async getIndividualStudentVideoUrl(id: string, videoName: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getIndividualStudentVideoUrl',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}/me/videos/${videoName}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async approveStudent(id: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'approveStudent',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/approve`,
                    { id },
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async rejectStudent(id: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'rejectStudent',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/reject`,
                    { id },
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async reserveStudent(id: string, note: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'reserveStudent',
            () => this.call('POST', `${this.RESOURCE}/preserve`, { id, note }, this.fetchOptions),
            asyncDataOptions
        )
    }

}

export default StudentModule
