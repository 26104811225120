import { acceptHMRUpdate, defineStore } from 'pinia'
import { UserRole } from '~/enums'
import type { LoginResponse } from '~/types'
import { useLoginContext } from '@/pages/login/useLoginContext'

export const useMyAuthStore = defineStore({
  id: 'myAuthStore',
  state: () => ({
    profile: null as LoginResponse | null
  }),
  getters: {
    isAdmin: (state) => state.profile?.roleId === UserRole.Admin,
    isEmployee: (state) => state.profile?.roleId === UserRole.Employee,
    isStudent: (state) => state.profile?.roleId === UserRole.Student,
    getProfile: (state) => state.profile
  },
  actions: {
    setProfile(profile: LoginResponse) {
      this.profile = profile
    },
    async getProfileHandler() {
      const { getProfileHandler } = useLoginContext()
      const result = await getProfileHandler()
      const profile = result.data.value as LoginResponse

      if (!profile) return
      this.setProfile(profile)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMyAuthStore, import.meta.hot))
}
