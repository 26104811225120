import * as localForage from 'localforage';
import { logger, type LogInstance } from '@/services';

export abstract class LocalPreference<T> {
    protected logger: LogInstance;

    abstract deserialize(value: object): T;

    protected constructor(private key: string) {
        this.logger = logger.withTag('LocalPreference').withTag(this.key);
    }

    async get(): Promise<T | undefined> {
        const item = await localForage.getItem<object>(this.key);
        if (item == null) return;
        return this.deserialize(item);
    }

    async set(value: T): Promise<void> {
        this.logger.withTag('set').info(value);
        await localForage.setItem<T>(this.key, value);
    }

    async delete(): Promise<void> {
        this.logger.withTag('delete').info('');
        await localForage.removeItem(this.key);
    }
}
