import { LocalPreference } from './LocaPref';

export class TokenLocalPref extends LocalPreference<string> {
    constructor() {
        super('token');
    }

    deserialize(value: object): string {
        return `${value}`
    }
}

export function token(): TokenLocalPref {
    return new TokenLocalPref();
}
