import type { FetchOptions } from 'ofetch';
import { type AsyncDataOptions, useAsyncData } from '#app';
import FetchFactory from '../factory';
import type { Setting } from '~/types';

class SettingModule extends FetchFactory<unknown> {
    private RESOURCE = '/systems'

    private fetchOptions: FetchOptions<'json'> = {
        headers: {
            'Accept-Language': 'en-US',
            'Content-Type': 'application/json'
        }
    }

    async getSettings(asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getSettings',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async updateSetting(setting: Setting, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'updateSetting',
            () => {
                return this.call(
                    'PUT',
                    `${this.RESOURCE}`,
                    {
                        systemKey: setting.systemKey,
                        systemValue: setting.systemValue
                    },
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }
}

export default SettingModule
