import { defineNuxtRouteMiddleware, navigateTo } from '#imports'
import { storeToRefs } from 'pinia'
import { token } from '~/data/local'
import { useMyAuthStore } from '~/stores/auth.store'

export default defineNuxtRouteMiddleware(async (to) => {
  // Skip middleware for prerendered/SSR pages
  if (import.meta.server) {
    return
  }

  // Prevent infinite redirects by checking if already on target route
  if (
    to.path === '/login' ||
    to.path === '/student/profile' ||
    to.path === '/admin/students'
  ) {
    return
  }

  const publicRoutes = ['/', '/login', '/register']
  const isPublicRoute = publicRoutes.includes(to.path)

  // For client-side only routes
  try {
    const tokenPref = token()
    const tokenValue = await tokenPref.get()
    const isAuthenticated = !!tokenValue

    // Handle public routes with authentication
    if (isPublicRoute && isAuthenticated) {
      const authStore = useMyAuthStore()
      const { getProfileHandler } = authStore
      const { isAdmin, isEmployee, isStudent, profile } = storeToRefs(authStore)

      if (!profile.value) {
        await getProfileHandler()
      }

      if (isAdmin.value || isEmployee.value) {
        return navigateTo('/admin/students')
      }
      if (isStudent.value) {
        return navigateTo('/student/profile')
      }
      return
    }

    // Handle protected routes
    if (!isPublicRoute && !isAuthenticated) {
      return navigateTo('/login')
    }

    // Handle role-based access for authenticated users
    if (!isPublicRoute && isAuthenticated) {
      const authStore = useMyAuthStore()
      const { isAdmin, isEmployee, isStudent, profile } = storeToRefs(authStore)

      if (!profile.value) {
        await authStore.getProfileHandler()
      }

      const adminRoutes = [
        '/admin',
        '/admin/students',
        '/admin/class',
        '/admin/settings'
      ]
      const studentRoutes = ['/student/profile', '/student/reports']
      const isAdminRoute = adminRoutes.some((route) =>
        to.path.startsWith(route)
      )
      const isStudentRoute = studentRoutes.some((route) =>
        to.path.startsWith(route)
      )

      if (isAdminRoute && !(isAdmin.value || isEmployee.value)) {
        return navigateTo('/student/profile')
      }

      if (isStudentRoute && !isStudent.value) {
        return navigateTo('/admin/students')
      }
    }
  } catch (error) {
    console.error('Auth middleware error:', error)
    return navigateTo('/login')
  }
})
