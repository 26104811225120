<script setup lang="ts">
  interface IAnimate {
    timing(p: number): number
    draw(p: number): void
    duration: number
  }

  function animate(param: IAnimate): void {
    const { timing, draw, duration } = param
    const start = performance.now()
    requestAnimationFrame(function animate(time) {
      let timeFraction = (time - start) / duration
      if (timeFraction > 1) timeFraction = 1
      const progress = timing(timeFraction)
      draw(progress)
      if (timeFraction < 1) {
        requestAnimationFrame(animate)
      }
    })
  }

  const timing = {
    linear(timeFraction: number): number {
      return timeFraction
    },
    quad(timeFraction: number, n = 2): number {
      return Math.pow(timeFraction, n)
    },
    circle(timeFraction: number): number {
      return 1 - Math.sin(Math.acos(timeFraction))
    },
    easeOutCubic(timeFraction: number): number {
      return 1 - Math.pow(1 - timeFraction, 3)
    },
    easeInOutQuart(timeFraction: number): number {
      return timeFraction < 0.5
        ? 8 * timeFraction * timeFraction * timeFraction * timeFraction
        : 1 - Math.pow(-2 * timeFraction + 2, 4) / 2
    }
  }

  const pageTransition = {
    name: 'page',
    mode: 'out-in' as const,
    onBeforeEnter: (el: Element) => {
      const element = el as HTMLElement
      element.style.opacity = '0'
      element.style.transform = 'scale(0.98)'
    },
    onEnter: (el: Element) => {
      const element = el as HTMLElement
      animate({
        timing: timing.easeInOutQuart,
        draw: (progress) => {
          element.style.opacity = `${progress}`
          element.style.transform = `scale(${0.98 + progress * 0.02})`
        },
        duration: 400
      })
    },
    onBeforeLeave: (el: Element) => {
      const element = el as HTMLElement
      element.style.opacity = '1'
      element.style.transform = 'scale(1)'
    },
    onLeave: (el: Element) => {
      const element = el as HTMLElement
      animate({
        timing: timing.easeInOutQuart,
        draw: (progress) => {
          element.style.opacity = `${1 - progress}`
          element.style.transform = `scale(${1 - progress * 0.02})`
        },
        duration: 400
      })
    }
  }
</script>

<template>
  <NuxtLayout class="min-h-screen">
    <NuxtPage :transition="pageTransition" />
  </NuxtLayout>
</template>
