import { default as indextUdyk025xNMeta } from "/vercel/path0/src/pages/admin/class/detail/index.vue?macro=true";
import { default as indexec125OZwCxMeta } from "/vercel/path0/src/pages/admin/class/index.vue?macro=true";
import { default as useAdminClassContextaVSBm8d4FZMeta } from "/vercel/path0/src/pages/admin/class/useAdminClassContext.ts?macro=true";
import { default as index1kcBTKlDMxMeta } from "/vercel/path0/src/pages/admin/index.vue?macro=true";
import { default as index6tD8yuIDaoMeta } from "/vercel/path0/src/pages/admin/settings/index.vue?macro=true";
import { default as useSettingContextbK56DxnowkMeta } from "/vercel/path0/src/pages/admin/settings/useSettingContext.ts?macro=true";
import { default as _91id_93uKa8REz1OuMeta } from "/vercel/path0/src/pages/admin/students/detail/[id].vue?macro=true";
import { default as indexDuPscwfCdpMeta } from "/vercel/path0/src/pages/admin/students/index.vue?macro=true";
import { default as useStudentContext8YAvusxOoiMeta } from "/vercel/path0/src/pages/admin/students/useStudentContext.ts?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as index2e6AjHz3h2wMeta } from "/vercel/path0/src/pages/index2.vue?macro=true";
import { default as indexlnITsMdT4bMeta } from "/vercel/path0/src/pages/login/index.vue?macro=true";
import { default as useLoginContextpZVKmePa9yMeta } from "/vercel/path0/src/pages/login/useLoginContext.ts?macro=true";
import { default as indexRomZBcI4ExMeta } from "/vercel/path0/src/pages/register/index.vue?macro=true";
import { default as useRegisterContextAmVu6YvfbyMeta } from "/vercel/path0/src/pages/register/useRegisterContext.ts?macro=true";
import { default as indexrnEhp3iVgOMeta } from "/vercel/path0/src/pages/student/profile/index.vue?macro=true";
import { default as useStudentProfileContextkxnIFppvg7Meta } from "/vercel/path0/src/pages/student/profile/useStudentProfileContext.ts?macro=true";
import { default as indexInYWW1y7vxMeta } from "/vercel/path0/src/pages/student/reports/index.vue?macro=true";
import { default as useStudentReportContext0M0LqruIVsMeta } from "/vercel/path0/src/pages/student/reports/useStudentReportContext.ts?macro=true";
export default [
  {
    name: "admin-class-detail___en",
    path: "/admin/class/detail",
    meta: indextUdyk025xNMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/class/detail/index.vue")
  },
  {
    name: "admin-class___en",
    path: "/admin/class",
    meta: indexec125OZwCxMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/class/index.vue")
  },
  {
    name: "admin-class-useAdminClassContext___en",
    path: "/admin/class/useAdminClassContext",
    component: () => import("/vercel/path0/src/pages/admin/class/useAdminClassContext.ts")
  },
  {
    name: "admin___en",
    path: "/admin",
    meta: index1kcBTKlDMxMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/index.vue")
  },
  {
    name: "admin-settings___en",
    path: "/admin/settings",
    meta: index6tD8yuIDaoMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/settings/index.vue")
  },
  {
    name: "admin-settings-useSettingContext___en",
    path: "/admin/settings/useSettingContext",
    component: () => import("/vercel/path0/src/pages/admin/settings/useSettingContext.ts")
  },
  {
    name: "admin-students-detail-id___en",
    path: "/admin/students/detail/:id()",
    meta: _91id_93uKa8REz1OuMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/students/detail/[id].vue")
  },
  {
    name: "admin-students___en",
    path: "/admin/students",
    meta: indexDuPscwfCdpMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/students/index.vue")
  },
  {
    name: "admin-students-useStudentContext___en",
    path: "/admin/students/useStudentContext",
    component: () => import("/vercel/path0/src/pages/admin/students/useStudentContext.ts")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexkP7wmsbr11Meta || {},
    component: () => import("/vercel/path0/src/pages/index.vue")
  },
  {
    name: "index2___en",
    path: "/index2",
    component: () => import("/vercel/path0/src/pages/index2.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: indexlnITsMdT4bMeta || {},
    component: () => import("/vercel/path0/src/pages/login/index.vue")
  },
  {
    name: "login-useLoginContext___en",
    path: "/login/useLoginContext",
    component: () => import("/vercel/path0/src/pages/login/useLoginContext.ts")
  },
  {
    name: "register___en",
    path: "/register",
    meta: indexRomZBcI4ExMeta || {},
    component: () => import("/vercel/path0/src/pages/register/index.vue")
  },
  {
    name: "register-useRegisterContext___en",
    path: "/register/useRegisterContext",
    component: () => import("/vercel/path0/src/pages/register/useRegisterContext.ts")
  },
  {
    name: "student-profile___en",
    path: "/student/profile",
    meta: indexrnEhp3iVgOMeta || {},
    component: () => import("/vercel/path0/src/pages/student/profile/index.vue")
  },
  {
    name: "student-profile-useStudentProfileContext___en",
    path: "/student/profile/useStudentProfileContext",
    component: () => import("/vercel/path0/src/pages/student/profile/useStudentProfileContext.ts")
  },
  {
    name: "student-reports___en",
    path: "/student/reports",
    meta: indexInYWW1y7vxMeta || {},
    component: () => import("/vercel/path0/src/pages/student/reports/index.vue")
  },
  {
    name: "student-reports-useStudentReportContext___en",
    path: "/student/reports/useStudentReportContext",
    component: () => import("/vercel/path0/src/pages/student/reports/useStudentReportContext.ts")
  }
]