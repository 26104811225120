import { defineNuxtPlugin, useRuntimeConfig, navigateTo } from '#app'
import { $fetch, type FetchOptions } from 'ofetch'
import { token } from '@/data/local'
import { logger } from '@/services/logger'
import type { ValidationErrorResponse } from '@/types'
import { ElMessage } from 'element-plus'

import {
  AuthModule,
  StudentModule,
  ClassModule,
  SettingModule,
  UserMeasurementModule
} from '~/repository/modules'

interface IApiInstance {
  auth: AuthModule
  student: StudentModule
  class: ClassModule
  setting: SettingModule
  userMeasurement: UserMeasurementModule
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const { apiBaseUrl } = config.public

  const fetchOptions: FetchOptions = {
    baseURL: apiBaseUrl as string,
    async onRequest({ options }) {
      const tokenPref = token()
      const tokenValue = await tokenPref.get()
      if (tokenValue) {
        const parsedToken = JSON.parse(tokenValue)
        options.headers.set(
          'Authorization',
          `Bearer ${parsedToken.accessToken}`
        )
      }
    },
    async onResponseError({ response }) {
      if (response.status === 429) {
        ElMessage.error('Bạn đã gửi quá nhiều yêu cầu. Vui lòng thử lại sau.')
        return
      }

      if (response.status === 401) {
        // Handle token expiration
        const tokenPref = token()
        const tokenValue = await tokenPref.get()

        if (tokenValue) {
          const parsedToken = JSON.parse(tokenValue)
          try {
            // Try to refresh token
            const refreshResult = await $fetch('/auth/refresh', {
              method: 'POST',
              body: {
                refreshToken: parsedToken.refreshToken
              }
            })

            if (refreshResult.accessToken) {
              // Update tokens
              await tokenPref.set(
                JSON.stringify({
                  accessToken: refreshResult.accessToken,
                  refreshToken:
                    refreshResult.refreshToken || parsedToken.refreshToken
                })
              )
              return // Continue with original request
            }
          } catch (error) {
            logger.error('Token refresh failed:', error)
            // Clear token and redirect to login if refresh token is invalid/expired
            if (
              (error as any)?.response?._data?.errors?.RefreshToken?.includes(
                'Invalid or expired refresh token'
              )
            ) {
              await tokenPref.delete()
              navigateTo('/login')
              return
            }
          }
        }

        // If refresh failed or no token exists, clear token and redirect to login
        await tokenPref.delete()
        navigateTo('/login')
      }

      const errorData = response._data as ValidationErrorResponse
      if (errorData?.errors) {
        logger.error('Validation Errors:', errorData.errors)
      }
    }
  }

  // Create a new instance of $fetcher with custom option
  const apiFetcher = $fetch.create(fetchOptions)

  // An object containing all repository we need to expose
  const modules: IApiInstance = {
    auth: new AuthModule(apiFetcher),
    student: new StudentModule(apiFetcher),
    class: new ClassModule(apiFetcher),
    setting: new SettingModule(apiFetcher),
    userMeasurement: new UserMeasurementModule(apiFetcher)
  }

  return {
    provide: {
      api: modules
    }
  }
})
