import { createConsola, type ConsolaInstance } from 'consola';

export const logger = createConsola({
    formatOptions: {
        columns: 2,
        colors: true,
        compact: true,
        date: true,
    },
});
export type LogInstance = ConsolaInstance;
